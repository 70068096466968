@import "../../base/fn";

@lineColor: #E5E5E5;
@grayColor: #999999;

.weui_media_box {
    padding: 15px;
    position: relative;
    &:before {
        .setTopLine(@lineColor);
        left: 15px;
    }
    &:first-child {
        &:before {
            display: none
        }
    }

    a&{
        color:#000000;
        .setTapColor;
        &:active{
            background-color:#ECECEC;
        }
    }

    .weui_media_title {
        font-weight: 400;
        font-size: 17px;
        .ellipsis();
        word-wrap: break-word;
        word-break: break-all;
    }
    .weui_media_desc {
        color: @grayColor;
        font-size: 13px;
        line-height: 1.2;
        .ellipsisLn(2);
    }

    &.weui_media_text {
        .weui_media_title {
            margin-bottom: 8px;
        }
        .weui_media_info {
            margin-top: 15px;
            padding-bottom: 5px;
            font-size: 13px;
            color: #CECECE;
            line-height: 1em;
            list-style: none;
            overflow: hidden;
        }
        .weui_media_info_meta {
            float: left;
            padding-right: 1em;
            &.weui_media_info_meta_extra {
                padding-left: 1em;
                border-left: 1px solid #CECECE;
            }
        }

    }
    &.weui_media_appmsg {
        display: flex;
        align-items: center;
        .weui_media_hd {
            margin-right: .8em;
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
        }
        .weui_media_appmsg_thumb {
            width: 100%;
            max-height: 100%;
            vertical-align: middle;
        }
        .weui_media_bd {
            flex: 1;
            min-width: 0;
        }
    }
    &.weui_media_small_appmsg {
        padding: 0;
        .weui_cells {
            margin-top: 0;
            &:before {
                display: none;
            }
        }
        .weui_cells_access {
        }
    }
}