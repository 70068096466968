@import "../../base/fn";
@import "tabbar";
@import "navbar";

.weui_tab {
    position: relative;
    height: 100%;
}

.weui_tab_bd {
    box-sizing: border-box;
    height: 100%;
    padding-bottom: 55px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.weui_tab_bd_item {
    display: none;
}